.communication-table th {
  font-weight: 600 !important;
}

.communication-table td>p {
  margin-bottom: 0;
}

.communication-table tbody tr:hover>td {
  background-color: #fff !important;
  color: #000 !important;
  font-weight: 500 !important;
  cursor: pointer;
}

.dropdown-tab-here span.MuiTab-wrapper {
  display: block;
}

.dropdown-tab-here>span.MuiTab-wrapper>svg.MuiSvgIcon-root {
  float: right;
  align-items: flex-end;
}

.communication-table {
  text-transform: capitalize !important;
  min-width: 650px;
}

.communication-table td.Sales,
.Sales,
.Sales>div>div>select {
  color: #cc0505;
}

.communication-table td.Support,
.Support,
.Support>div>div>select {
  color: #008000;
}

.communication-table td.Account,
.Account,
.Account>div>div>select {
  color: #fdbe36;
}

.communication-table td.Technical,
.Technical,
.Technical>div>div>select {
  color: #342fc8;
}

.communication-table td.Other,
.Other,
.Other>div>div>select {
  color: #e621c5;
}

.communication-table td svg.delete-icon,
.communication-table td.important-icon i {
  cursor: pointer;
}

.communication-table td.important-icon i.fa-star-o::before,
.communication-table td.important-icon i.fa-star::before {
  color: #cc0505;
}

.fa {
  color: #cc0505;
}

.main-pagination-tag .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: "#E64934 !important";
}

.logbutton {
  text-align: end;
}

/* button style */
.logbutton .btn-style {
  padding: 12px 30px;
  font-weight: 600;
  min-width: 200px;
  font-size: 18px;
  color: #fff;
  background: #23a751;
  border-radius: 50px;
  text-transform: capitalize;
  transition: .5s all;
  -moz-transition: .5s all;
  box-shadow: none;
}

.logbutton .btn-style:hover {
  background: #23a751;
  color: #fff;
  transition: .5s all;
  -moz-transition: .5s all;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}

.logbutton .btn-style-white {
  padding: 12px 30px;
  font-weight: 600;
  min-width: 200px;
  font-size: 18px;
  color: #23a751;
  background: none;
  border: solid 1px #23a751;
  border-radius: 50px;
  text-transform: capitalize;
  transition: .5s all;
  -moz-transition: .5s all;
  box-shadow: none;
}

.logbutton .btn-style-white:hover {
  padding: 12px 30px;
  font-weight: 600;
  min-width: 200px;
  font-size: 18px;
  color: #23a751 !important;
  background: none;
  border: solid 1px #23a751;
  border-radius: 50px;
  text-transform: capitalize;
  transition: .5s all;
  -moz-transition: .5s all;
  box-shadow: none;
}

.buttonRe .bn34 {
  background: none;
  cursor: pointer;
  text-decoration: inherit;
  font-size: 1rem;
  color: #000;
  padding: 0.7rem 2rem;
  border-image-slice: 1;
  border: none;
}

.buttonRe .bn34:hover {
  background: none;
  cursor: pointer;
  text-decoration: inherit;
  font-size: 1rem;
  color: #23a751;
  padding: 0.7rem 2rem;
  border-image-slice: 1;
  border: none;
}

.css-1of02t1-MuiButtonBase-root-MuiIconButton-root {
  margin: 6px !important;
}

.css-376f3r {
  margin-right: 20px !important;
}



.btn-success {
  background-color: #000;
  border: none;
  /* min-width: 250px; */
  font-size: 15px;
  border-radius: 50px;
  text-transform: capitalize !important;
}

.btn-outline-success {
  background-color: none;
  border: solid 1px #000;
  /* min-width: 250px; */
  font-size: 15px;
  border-radius: 50px;
  text-transform: capitalize !important; 
}

/*.btn-success:hover {
  background-color: #23a751;
  border: none;
  min-width: 250px;
  font-size: 18px;
  border-radius: 50px;
  box-shadow: 5px 5px 20px rgba(31, 182, 99, 0.4);
}

.btn-outline-success:hover {
  background-color: #23a751;
  border: solid 1px #23a751;
  min-width: 250px;
  font-size: 18px;
  border-radius: 50px;
  box-shadow: 5px 5px 20px rgba(31, 182, 99, 0.4);
}

.buttonRe .btn-primary {
  color: #000;
  border: none;
  background-color: rgb(13 110 253 / 0%);
} */

.button123 .saveButton{
  border-radius: 40px !important;
  background-color: #00A651 !important;
  border: 1px solid #00A651 !important;
  color: #ffffff !important;
  padding: 0 35px !important ;
  height: 40px !important;
  font-family: Arial !important;
  font-size: 14px !important;
  text-transform: capitalize;
}

.other-login {
  text-align: center;
}

.Main_Card .MuiLinearProgress-colorPrimary {
  background-color: rgb(173, 219, 159);
}

.Main_Card .MuiLinearProgress-barColorPrimary {
  background-color: #00a651;
}

.Radio .MuiRadio-colorSecondary.Mui-checked {
  color: #23a751;
}

.form-textfield-styles {
  margin-bottom: 15px;
}

.MuiFormControl-root.form-textfield-styles {
  margin-bottom: 15px;
}

.form-textfield-styles label {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.form-textfield-styles label.Mui-focused {
  color: #000;
}

.form-textfield-styles .MuiInput-underline:after {
  border-bottom: 2px solid #000;
}

.Switch-main .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #00a651;
}

.Switch-main .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #72c499;
}

.Pagination .css-kvsszq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: rgb(231 60 34);
  color: #ffffff;
  border: rgb(231 60 34);
}

.hrline {
  height: 3px !important;
}

.Sttext1 .MuiTypography-subtitle1 {
  font-weight: 700;
}

.stline {
  margin: 20px 0;
  border: 1px solid #797979;
}

/* chat css */

.icon2 .MuiIconButton-root {
  background-color: none !important;
  border-radius: 0%;
}

.icon2 .MuiIconButton-root:hover {
  border-radius: 0%;
  background-color: rgba(0, 0, 0, 0) !important;
}

/* header .css-1t29gy6-MuiToolbar-root {
  min-height: 96px !important;
  padding: 0px 0px 0px 0px;
}
@media (min-width: 600px) {
  header .css-1t29gy6-MuiToolbar-root {
    min-height: 96px !important;
    padding: 0px 0px 0px 0px;
  }
} */

@media (max-width: 768px) {
  .IconTest {
    display: none;
  }
}

/* end chat css */

/* program Stepper */

.stepper-css span>span.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
  padding: 6px;
}

.stepper-css span>span.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel svg.QontoStepIcon-completedIcon {
  color: #fff;
  font-size: 15px;
  background-color: #00a651;
  border-radius: 50%;
  padding: 2px;
  font-weight: bold;
}

.stepper-css span>span.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel div.QontoStepIcon-circle {
  color: #fff;
  font-size: 15px;
  background-color: #bdbdbd;
  border-radius: 50%;
  padding: 2px;
  font-weight: bold;
  height: 1em;
  width: 1em;
}

div.MuiStepConnector-completed,
div.MuiStepConnector-active,
div.Mui-disabled {
  left: calc(-50% + 7px);
  right: calc(50% + 7px);
}

.form-textfield-styles div.Mui-disabled {
  left: 0;
  right: 0;
}

.MuiStepConnector-completed>span.MuiStepConnector-line {
  border-color: #00a651;
}

.stepper-css .MuiStepConnector-lineHorizontal {
  border-top-style: solid;
  border-top-width: 3px;
}

span.MuiStepLabel-label.MuiStepLabel-completed {
  color: #00a651;
  font-weight: 600;
}

span.MuiStepLabel-label.MuiStepLabel-active,
span.MuiStepLabel-label {
  color: #bdbdbd;
  font-weight: 600;
}

/* .dfdsf {
  flex-direction: column-reverse;
} */

.Main_Card {
  box-shadow: 2px 3px 10px #9e9e9e;
  margin-bottom: 40px;
  /* min-height: 600px; */
}

.Main_Card .MuiLinearProgress-colorPrimary {
  background-color: rgb(173, 219, 159);
}

.Main_Card .MuiLinearProgress-barColorPrimary {
  background-color: #00a651;
}

.lableMain .MuiFormLabel-root {
  color: rgb(48, 48, 48);
  font-size: 1.2rem;
  font-weight: 500;
}

.stepperButton {
  text-align: end;
}

.btn-Nextbutton {
  background-color: #23a751;
  border: solid 1px #23a751;
  min-width: 150px;
  font-size: 18px;
  border-radius: 50px;
}

.btn-Nextbutton:hover {
  background-color: #23a751;
  border: solid 1px #23a751;
  min-width: 150px;
  font-size: 18px;
  border-radius: 50px;
  box-shadow: 5px 5px 20px rgba(31, 182, 99, 0.4);
}

.btn-backbutton {
  background-color: transparent;
  border: solid 1px #23a751;
  min-width: 150px;
  font-size: 18px;
  border-radius: 50px;
  color: #23a751;
}

.btn-backbutton:hover {
  background-color: #23a751;
  border: solid 1px #23a751;
  min-width: 150px;
  font-size: 18px;
  border-radius: 50px;
  color: #ffffff;
  box-shadow: 5px 5px 20px rgba(31, 182, 99, 0.4);
}

.stline {
  margin: 20px 0;
  border: solid 1px #797979;
}

.mainFont .css-qivjh0-MuiStepLabel-label {
  font-family: Arial;
}

.mainButton {
  text-align: right;
}

.mainButton .saveEmailButton1 {
  border-radius: 40px;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000;
  padding: 0 35px;
  height: 40px;
  font-family: Arial;
  text-transform: capitalize !important;
}

.mainButton .saveEmailButton1:hover {
  border-radius: 40px;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000;
  padding: 0 35px;
  height: 40px;
  font-family: Arial;
}

.MuiPickersToolbar-toolbar {
  background-color: #00a651 !important;
}

.MuiPickersDay-daySelected{
  background-color: #00a651 !important;
}

.errorBottom {
  margin-bottom: 0px !important;
}

.btn{
  text-transform: capitalize !important;
}

.multi-row-add .add-more {
  padding: 10px 10px;
  font-weight: 600;
  color: #fff;
  background: #23a751;
  border: none;
  text-transform: capitalize;
  transition: .5s all;
  -moz-transition: .5s all;
}

.multi-row-add .remove-button {
  padding: 10px 10px;
  font-weight: 600;
  color: #fff;
  background: #e24517;
  border: none;
  text-transform: capitalize;
  transition: .5s all;
  -moz-transition: .5s all;
}

.multi-row-add .add-more1 {
  padding: 10px 10px;
  font-weight: 600;
  color: #fff;
  background: #23a751;
  border: none;
  text-transform: none;
  transition: .5s all;
  -moz-transition: .5s all;
  border-radius: 5px;
}

/* End Program Stepper */